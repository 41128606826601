
//Environment specific configuration
const env = process.env.NODE_ENV === 'production' ? window.env : process.env;
export const authConfigSetting = {
    CLIENT_ID: env.REACT_APP_CLIENT_ID,
    AUTHORITY: env.REACT_APP_AUTHORITY,
    graphURL: env.REACT_APP_GRAPHURL
}
  
export const USER_GROUP = env.REACT_APP_USER_GROUP;
export const API_BASE_URL = env.REACT_APP_API_BASE_URL;