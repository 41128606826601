// Constants
import {authConfigSetting} from "../appConfig";
export const msalConfig = {
  auth: {
    clientId: authConfigSetting.CLIENT_ID,//Application ID is also known as client ID
    authority: authConfigSetting.AUTHORITY,
    redirectUri: "/",//redirect uri specifies navigation after successful authentication
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }, 
};

//For user specific
export const loginRequest = {
  scopes: [],
};