import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Tooltip } from '@mui/material';

import { Button } from '../stories/Button';
import { svgIconsList } from "../constants/appConstants";

import { setActiveCustomer } from "../state/dashboardSlice";

import "./dropdown.scss";

export function Dropdown(props) {

    const releases = useSelector((state) => state.dashboard.releases);
    const activeCustomers = useSelector((state) => state.dashboard.activeCustomers);
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const [dropdown, setdropdown] = useState(props.default ? props.default.split(',') : []);
    const [dropdownActive, setDropdownActive] = useState(false);
    const [actCustomer, setActiveCustomerData] = useState([]);

    //default
    const defaultStatus = useSelector((state) => state.dashboard.defaultStatus);
    const defaultProducts = useSelector((state) => state.dashboard.defaultProducts);
    const defaultCustomers = useSelector((state) => state.dashboard.defaultCustomers);
    const defaultEnvironments = useSelector((state) => state.dashboard.defaultEnvironments);
    const defaultReleases = useSelector((state) => state.dashboard.defaultReleases);


    const dropdownListClick = (value) => {
        let dayName = value?.Value;
        let ele = dropdown?.indexOf(dayName) > -1;
        let temp = [], newArr; temp["Id"] = value.Id; temp["dropdownName"] = props.name;

        if (!dropdown) {
            temp['Value'] = value.Value;
            setdropdown(temp);
            return false;
        }
        if (props.option === 'multiselect') {//for mulsti-select dropdown
            if (ele) {
                newArr = dropdown.filter(value => value !== dayName);
            } else {
                newArr = [...dropdown, dayName]
            }

            if (newArr[0] === 'Select a customer') {
                newArr.shift('Select a customer');
            }

            if (newArr.length === 0 && props.name === 'customercodes') {
                newArr.push("Select a customer");
            }

            setdropdown(newArr);
            temp['Value'] = newArr;

        } else {
            setdropdown([value.Value]);
            temp['Value'] = [value.Value];
        }

        props.changeDropdown(temp);

        //update when user change cust dropdown
        if (props.name === 'customercodes') {
            const cust = temp['Value'].join(',');
            setActiveCustomerData(cust)
        }
    };

    //close dropdown when click outside
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setDropdownActive(false)
        }
    };

    // detect outside click event handle here
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
    }, []);

    //call other apis only when we hide the dropdown container and the customer has data
    useEffect(() => {
        if (props.name === 'customercodes' && dropdownActive === false) {
            dispatch(setActiveCustomer(actCustomer))
        }
    }, [actCustomer, dropdownActive]);

    //on reset clear selection button rest dropdown
    useEffect(() => {
        if (props.name === 'customercodes' && !activeCustomers) {
            setdropdown(['Select a customer']);
        }
    }, [activeCustomers]);

    useEffect(() => {
        let dropdownValue = props?.dropdownList?.[0]?.['Value']?.split(',');
        if (props.name === 'customercodes') {
            if (dropdownValue?.[0] === "Select a customer") {
            } else if (actCustomer.length >= 1) {
            } else {
                if (defaultCustomers) {
                    dropdownValue = defaultCustomers.split(",");
                }
                setdropdown(dropdownValue);
            }
        } else {
            if (props.name === 'status') {
                dropdownValue = defaultStatus?.split(",");
            } else if (props.name === 'products') {
                dropdownValue = defaultProducts?.split(",");
            }
            else if (props.name === 'environments') {
                dropdownValue = defaultEnvironments?.split(",");
            }
            else if (props.name === 'releases') {
                dropdownValue = defaultReleases?.split(",");
            }

            if (!activeCustomers || activeCustomers === "Select a customer") {
                dropdownValue = [];//reset
            }
            setdropdown(dropdownValue);
        }
    }, [releases]);

    return (
        <>
            <div className={`dropdown dropdown-${props.name}`} ref={wrapperRef}>
                <Button label="" type="button" size="large" border onClick={() => setDropdownActive(!dropdownActive)} className={`inprogress-${props.dropdownLoader}`}>
                    {<Tooltip title={dropdown?.join(', ')}><span>{dropdown?.join(', ')}</span></Tooltip>}
                    {/*{props.option !== 'multiselect' && <span>{dropdown?.join(', ')}</span>} */}
                    <img height={15} width={10} className="icons" src={svgIconsList.DARROW} alt="edit scan schdule" />
                </Button>
                <ul className={`dropdown-menu ${props.Value} items-${props.dropdownList?.length} display-${dropdownActive}`}>
                    {(typeof props.dropdownList !== 'string') && props.dropdownList.map((value, _index) => {
                        let className = dropdown?.indexOf(value.Value) > -1 ? "active" : '';
                        if (value.Id !== 0) {
                            return (<li key={value.Id + '-' + value.Value} className={`dropdown-item ${className}`} id={value.Id} onClick={() => dropdownListClick(value)}><span>{value.Value}</span></li>);
                        }
                    })}
                </ul>
            </div>
        </>
    );
}
