import React, { useState, useEffect, useCallback } from "react";
import { Container, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';

import { checkUserTokenValidity } from '../../util/util';

import { LinearIndeterminate } from '../../stories/Loader';
import DashobardHeader from "./dashboardHeader";
import DashobardContent from "./dashboardContent";
//Auth
import { useMsal } from "@azure/msal-react";
//service
import { executeQuery } from "../../services/execute";
import API from "../../services/APIConstants";
//state
import { setCustomers, setActiveCustomer, setDefaultCustomers } from "../../state/dashboardSlice";
import PopupContext from "./popupContext";

import './dashboard.scss';

const Dashboard = () => {
    const functionKey = useSelector((state) => state.user.functionKey)
    const [pageLoader, setPageLoader] = useState(true);
    const [dropdownLoader, setDropdownLoader] = useState(false);
    const [modal, setModal] = useState({ showModal: false, moreData: "", bigPopup: true, messageDescription: "", popupTitle: "" })

    const [data, setTableData] = useState([]);
    const [isDataAvailable, setIsDataAvailable] = useState('');
    const [render, setRender] = useState(true);
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const customers = useSelector((state) => state.dashboard.customers)

    const openStatusTable = (title, description, moreData = "") => {
        title = "What is status?"
        const tableData = [
            { status: "Queued", dec: "API is in queue and will be picked up for processing when 'In progress' APIs are completed.", class: "queue" },
            { status: "Failed-APIM", dec: "API publishing has been failed due to APIM unavailability. This can be retried by the user.", class: "fail" },
            { status: "Failed-Metadata", dec: "API publishing has been failed because of problem with Specs or the Index.json configuration. Click on flashlight icon to see details. Once correction is made redeploy the build.", class: "fault" },
            { status: "Skipped", dec: "API publishing is done incrementally. This API was skipped because no change was detected from the time it was published before.", class: "skipped" },
            { status: "In progress", dec: "API has been picked up for processing but not yet completed.", class: "inprogress" },
            { status: "Completed", dec: "API has been published.", class: "complete" }]
        const des = <table className="statusTable" border="1">
            <thead>
                <tr>
                    <th style={{ width: "15%" }}>Status</th>
                    <th style={{ width: "85%" }}>Description</th>
                </tr>
            </thead>
            <tbody >
                {tableData.map((row, index) => <tr className="statusRow" key={index}  >
                    <td style={{ width: "15%" }} className={row.class + " statusText"} >{row.status}</td>
                    <td style={{ width: "85%", paddingLeft: "5px" }} >{row.dec}</td>
                </tr>)}
            </tbody>
        </table>
        setRender(false);
        setModal({ showModal: true, bigPopup: true, dialogClassName: "statusModalCustom", moreData: moreData, messageDescription: des, popupTitle: title });
    }

    const openSmallPopup = useCallback((title, description, moreData = "") => {
        setRender(false);
        setModal({ showModal: true, dialogClassName: "smallModalCustom", bigPopup: false, moreData: moreData, messageDescription: description, popupTitle: title });
    })

    const handleCloseModal = useCallback(() => {
        setRender(false);
        setModal({ showModal: false, bigPopup: true });
    })

    const handleOpenModal = useCallback((title, description, moreData = "") => {
        setRender(false);
        setModal({ showModal: true, dialogClassName: "bigModalCustom", bigPopup: true, moreData: moreData, messageDescription: description, popupTitle: title, });
    })

    const handleGoClick = (values) => {
        const newValues = { ...values }
        const customerNames = getCustomerName(values.customercodes);
        newValues.customercodes = customerNames;

        const getTableData = async () => {
            setDropdownLoader(true)
            const tableData = await executeQuery(API.SEARCH, "POST", newValues);
            setRender(true);
            setTableData(tableData.data);
            setDropdownLoader(false);
        }
        getTableData();

        //check user token validaty
        checkUserTokenValidity(instance);
    }

    const getCustomerName = (Value) => {
        let temp = [];
        for (let r in Value) {
            temp[r] = customers.filter((item) => item.Id === Value[r])?.[0]?.['Value'];
        }
        return temp;
    }

    const clearTableData = () => {
        setTableData([])
    }

    //call the actual application API after the successfull access of function key
    useEffect(() => {
        const getCustomers = async () => {
            //super user
            const list = await executeQuery(API.COMMON_CUSTOMER, "GET");

            const modifiedCustomer = list.data.map(customer => {
                let obj = { ...customer }
                obj.Value = obj.Value + "(" + obj.Id + ")";
                return obj;
            });
            //Contextual search
            //if there is criteria from url then url is stored in localstorage and passed down
            if (window.location.search) {
                var iterator = new URLSearchParams(window.location.search).entries();
                for (let item of iterator) {
                    var name = item[0];
                    if (name === "c") {
                        dispatch(setDefaultCustomers(item[1]));
                        dispatch(setActiveCustomer(item[1]))
                        break;
                    }
                }
            }

            dispatch(setCustomers(modifiedCustomer));
            dispatch(setActiveCustomer(list.data?.[0]?.['Value']));
            setIsDataAvailable(list.data?.[0]?.['Value'])
            setPageLoader(false);
        }

        if (functionKey) {
            //check user token validaty
            checkUserTokenValidity(instance);
            getCustomers();
        }
    }, [functionKey])

    return (
        <>
            {pageLoader && <LinearIndeterminate />}
            <Container className={`dashboard-container pageloader-${pageLoader}`}>
                <PopupContext.Provider value={{ openStatusTable: openStatusTable }}>
                    <DashobardHeader handleGoClick={handleGoClick} clearTableData={clearTableData} openSmallPopup={openSmallPopup} handleOpenModal={handleOpenModal} isDataAvailable={isDataAvailable} />
                    <DashobardContent render={render} data={data} dropdownLoader={dropdownLoader} setDropdownLoader={setDropdownLoader} openSmallPopup={openSmallPopup} handleOpenModal={handleOpenModal} />
                </PopupContext.Provider>
                <Modal className="modalComponent" scrollable={true} dialogClassName={modal.dialogClassName || "smallModalCustom"} contentClassName="custom_modal_content" show={modal.showModal} backdropClassName="Overlay" animation={false} backdrop={"true"} centered onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modal.popupTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {modal.messageDescription}
                        </div>
                        {<div >
                            {modal.moreData}
                        </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button"
                            onClick={handleCloseModal} className="close btn btn-primary btn-sm btn-block closeButton" >Close</button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </>
    );
};

export default Dashboard;
