
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';


import { Button } from '../../stories/Button';
import { Heading } from '../../stories/Heading';
import { Input } from '../../stories/Input';

import { HeaderOuter } from '../../components/header/headerOuter';
import { FooterOuter } from '../../components/footer/footerOuter';

import { svgIconsList } from "../../constants/appConstants";
import { CONSTANTS } from "../../constants/stringConstants";

//Authentication
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../util/authConfig";

// Utility
import { isValidEmailID } from "../../util/util";

import "./login.scss";

export default function ForcePasswordReset() {

  const [email, setEmail] = useState('');
  const [className, setClassName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');

  const isAuthenticated = useIsAuthenticated();//will require later
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  //request for login and get accound details
  const loginRedirectCode = () => {
    instance.loginRedirect(loginRequest).catch(e => { });
  }

  const checkUserLoggedIn = () => {
    if (!accounts[0]) {//1) if not logged redirect to login
      loginRedirectCode()
    } else {//2) redired to welcome page and check user is first time or not
      handleRedirect();//3) create common function to handle redirection
    }
  }

  const handleRedirect = () => {
    if (isAuthenticated === false) {
      navigate('/login')
    } else {
      //Contextual search
      navigate({
        pathname: '/dashboard',
        search: window.localStorage.getItem("search"),
      })
    }
  }

  const emailChangeHandler = (e) => {
    const email = e.target.value;
    isValidEmailID(email) ? setClassName("") : setClassName("disable");
    setErrorEmail("");
    setEmail(email);
  };

  const onLoginClickHandler = () => {
    if (email.length === 0) {
      setErrorEmail(CONSTANTS.LOGIN.errorMessage)
    } else {
      checkUserLoggedIn();
    }
  };

  useEffect(() => {
    handleRedirect();
  }, [])

  return (
    <Container className={`login-reg-page`} fluid>
      <HeaderOuter />
      <Row align="center">
        <Col sm={12} lg={8} className={`left`}>
          <Row align="center">
            <Col sm={1} lg={3}> </Col>
            <Col sm={10} lg={6}>
              <Heading size="large" label="">{CONSTANTS.LOGIN.loginInstruction}</Heading>
              <br />
              <br />
              <form>
                <Row>
                  <Col>
                    <Heading label="" size="small">{CONSTANTS.LOGIN.lblEmailField}</Heading>
                    <Input
                      name="email"
                      value={email}
                      onChange={(e) => {
                        emailChangeHandler(e)
                      }}
                      size="large"
                      type="text"
                      label=""
                      className={errorEmail ? 'error' : ''}
                    />
                    {errorEmail && <div className='input-feedback'>
                      {errorEmail}
                    </div>}
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Button className={className} label='' type="button" size="extra-large" primary onClick={() => onLoginClickHandler()}>
                      {CONSTANTS.LOGIN.lblLoginButton}
                      <span><img src="assets/normal_open_in_new.svg" height="15" width="15" className="ico-new-win" alt="alt-"></img></span>
                    </Button>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <a href={CONSTANTS.LINKS.lnkServiceNow} target="_blank" className="help-link">{CONSTANTS.LOGIN.helpLink}</a>
                  </Col>
                </Row>
              </form>
              <FooterOuter />
            </Col>
            <Col sm={1} lg={3} > </Col>
          </Row>

        </Col>
        <Col sm={12} lg={8} className="right" style={{ backgroundImage: `url(${svgIconsList.BG_IMAGE})` }}>
        </Col>
      </Row>
    </Container>
  );
}
