import axiosInstance from "./axios.init";

// Constants
import { OPERATION_STATUS, API_ERROR_CODE } from "./axios.init";

export const callAPI = async (url, httpMethod, requestBody) => {
  const key = sessionStorage.getItem('x-functions-key');
  let headers = {"x-functions-key":key};
  
  return callActualAPI({
    url: url,
    method: httpMethod,
    data: httpMethod !== "GET" ? requestBody : null,
    responseType: "json",
    headers:headers
  });
};

const callActualAPI = async (apiConfiguration) => {
  // console.log(" request: ", apiConfiguration);

  // TODO: Add token for auth
  return axiosInstance(apiConfiguration)
    .then((response) => {
      return handleAPISuccess(response);
    })
    .catch((error) => {
      return handleAPIFailure(error);
    });
};

const handleAPISuccess = async (apiResponse) => {
  // console.log("Success Response ", apiResponse);
  return {
    status: OPERATION_STATUS.SUCCESS,
    data: apiResponse.data,
    error: {},
  };
};



const handleAPIFailure = (error) => {
  // console.log("Error API Response :- ", error?.message);
  let errorStatus = error.response?.status;
  let errorMsg = error?.message;
  // const navigate = useNavigate();
  if (errorStatus === API_ERROR_CODE.INTERNAL_SERVER_ERROR) {
    return {
      status: OPERATION_STATUS.FAILURE,
      data: {},
      error: "Internal Server Error!",
    };
  }
  else if (errorStatus === API_ERROR_CODE.UNAUTHORIZED) {
    // navigate("/");
    return {
      status: OPERATION_STATUS.FAILURE,
      data: {},
      error: errorMsg,
    };
  }
  else {
    return {
      status: OPERATION_STATUS.FAILURE,
      data: {},
      error: errorMsg ? errorMsg : `API request failed with status ${errorStatus}`,
    };
  }
};
