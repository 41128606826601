import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./dashboardSlice"
import userSlice from "./userSlice"

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    user: userSlice
  }
});

export default store;
