import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";

import { CONSTANTS } from "../../constants/stringConstants";

import  "./error.scss";

const Error = () => {
    return (
        <Container className='not-found'>
            <div className='inner'>
                <h1 className='not-found__heading'>
                    {CONSTANTS.ERROR.code}
                    <br/>
                    {CONSTANTS.ERROR.lblHeader}
                </h1>
                <p className='not-found__content'>{CONSTANTS.ERROR.infoMessage1}</p>
                <br/>
                <Link to="/dashboard" className="not-found__btn">{CONSTANTS.ERROR.infoMessage2}</Link>
            </div>
        </Container>
    );
};

export default Error;