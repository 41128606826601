import React from 'react';
import PropTypes from 'prop-types';
import './ButtonLoader.scss';

/**
 * Primary UI component for user interaction
 */
export const ButtonLoader = ({ _size, className, ...props }) => {
   return (
      <i
        className={[`storybook-button-loader ${className}`].join(' ')}
        {...props}
      >
      </i>
    );
  
};

ButtonLoader.propTypes = {
  /**
   * How large should the beading be?
   */
  size: PropTypes.oneOf(['large']),
};

ButtonLoader.defaultProps = {
  size: 'large'
}
