import React from "react";
import { Tooltip } from "@mui/material";
import moment from 'moment';
import PerfectScrollBar from "react-perfect-scrollbar";
import swal from 'sweetalert';

//Components
import { Heading } from "../../stories/Heading";

//Service
import { checkUserTokenValidity } from '../../util/util';
import { executeQuery } from "../../services/execute";

//Constants
import { svgIconsList } from "../../constants/appConstants";
import { tableConst } from "../../constants/stringConstants";
import API from "../../services/APIConstants";

//Css
import "./table.scss";
import "../../styles/font.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

import { MsalContext } from "@azure/msal-react";

class APITable extends React.Component {
    static contextType = MsalContext;

    constructor(props) {
        super(props);
        this.state = {
            [tableConst.apiName]: "down",
            [tableConst.status]: "down",
            [tableConst.createdOn]: "down",
            [tableConst.updatedOn]: "down",
            data: [],
            showModal: false,
            messageDescription: "",
            popupTitle: "",
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.render) {
            this.setState({ data: nextProps.data })
        }
    }

    handleSortChange = (name) => {
        const sortOrder = this.state[name] === 'up' ? 'down' : 'up';
        let { data } = this.state;
        this.setState((state) => {
            state[name] = sortOrder;
            return state;
        })
        if (sortOrder === 'up') {
            const newArr = [...data].sort((a, b) => (a[name].toUpperCase() > b[name].toUpperCase()) ? 1 : ((b[name].toUpperCase() > a[name].toUpperCase()) ? -1 : 0));
            this.setState({ data: newArr })
        }
        else {
            const newArr = [...data].sort((a, b) => (a[name].toUpperCase() > b[name].toUpperCase()) ? -1 : ((b[name].toUpperCase() > a[name].toUpperCase()) ? 1 : 0))
            this.setState({ data: newArr })
        }
    }

    handleActionClick = (index, action, rowKey, partitionKey, apiName) => {
        let query = "";
        switch (action) {
            case tableConst.retry:
                query = API.RETRY
                break;
            case tableConst.reset:
                query = API.RESET
                break;
        }
        const getActionData = async () => {
            const body = { "RowKey": rowKey, "PartitionKey": partitionKey };
            this.props.setDropdownLoader(true);
            const actionData = await executeQuery(query, "POST", body);
            const { data, status: apiStatus, message } = actionData;
            if (apiStatus === tableConst.success) {
                let newTableData = [...this.state.data];
                newTableData[index][tableConst.status] = data[tableConst.status]
                newTableData[index][tableConst.updatedOn] = data[tableConst.updatedOn]
                const updatedData = [...newTableData];
                this.setState({ data: updatedData });
            }
            else if (apiStatus === tableConst.failure) {
                const querymsg = action === tableConst.reset ? tableConst.resetFailed : tableConst.retryfailed
                this.props.openSmallPopup("Failed", querymsg, "Error: " + message)
            }
            this.props.setDropdownLoader(false);
        }
        checkUserTokenValidity(this.context.instance);

        swal({
            text: `Do you want to ${action === tableConst.retry ? 'retry' : 'reset'} '${apiName}' API?`,
            buttons: true,
        })
            .then((value) => {
                if (value) {
                    getActionData();
                }
            });
    }

    formatDescription = (descriptionStr) => {
        function resturnStr(elementStr) {
            if (elementStr != "") {
                if (elementStr.match(/[-------]/)) {
                    return <div style={{ fontFamily: "monospace", width: "100%" }}>{elementStr}</div>
                    //return <hr style={{ width: "100%", color: "black" }}></hr>
                }
                else { return <div>{elementStr}</div> }
            }
            else {
                return null;
            }
        }
        const strArray = descriptionStr.replace("Message=", "").trim().split("|");
        const finalArray = strArray.map(element => {
            return resturnStr(element);
        });
        return finalArray;
    }

    render() {
        const { data } = this.state;
        return ((<>
            <table className="customTable" border="1">
                <thead>
                    <tr height={40} style={{ paddingRight: "1%" }}>
                        <th style={{ width: "33%", padding: ".7%", paddingLeft: "1%" }}> <Heading label="" size="small">API Name <i onClick={() => { this.handleSortChange(tableConst.apiName) }} className={`arrow ${this.state[tableConst.apiName]}`}></i></Heading> </th>
                        <th style={{ width: "9%" }}> <Heading label="" size="small">Status <i onClick={() => { this.handleSortChange(tableConst.status) }} className={`arrow ${this.state[tableConst.status]}`}></i></Heading></th>
                        <th style={{ width: "15%" }}><Heading label="" size="small" >Created On <i onClick={() => { this.handleSortChange(tableConst.createdOn) }} className={`arrow ${this.state[tableConst.createdOn]}`}></i></Heading></th>
                        <th style={{ width: "15%" }} ><Heading label="" size="small">Updated On <i onClick={() => { this.handleSortChange(tableConst.updatedOn) }} className={`arrow ${this.state[tableConst.updatedOn]}`}></i></Heading></th>
                        <th style={{ width: "20%" }} ><Heading label="" size="small">Message</Heading></th>
                        <th style={{ width: "8%" }}><Heading label="" size="small">Action</Heading></th>
                    </tr>
                </thead>
                {data.length > 0 ? (<PerfectScrollBar component="tbody">
                    {[...data].map((p, index) => {
                        const action = p[tableConst.status] === tableConst.inProgress ? tableConst.reset : (p[tableConst.status] === tableConst.failed ? tableConst.retry : "")
                        return (
                            <tr height={40} key={p[tableConst.rowKey]}>
                                <Tooltip title={p[tableConst.apiName]}><td style={{ width: "33%", paddingLeft: "1%" }}> {p[tableConst.apiName]}</td></Tooltip>
                                <td style={{ width: "9%" }} >{p[tableConst.status]}</td>
                                <td style={{ width: "15%" }} >{moment(p[tableConst.createdOn]).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td style={{ width: "15%" }} > {moment(p[tableConst.updatedOn]).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <Tooltip title={p[tableConst.message]}><td style={{ width: "20%" }} >
                                    <span className="msgContainer">
                                        <span className="message">{p[tableConst.message]}</span>
                                        {p[tableConst.message] && p[tableConst.messageDescription] && <img className="searchIcon" src={svgIconsList.SEARCH_ICON} onClick={() => { const des = this.formatDescription(p[tableConst.messageDescription]); this.props.handleOpenModal(p[tableConst.apiName], des, "", tableConst.messageDescription) }} />}
                                    </span></td>
                                </Tooltip>
                                <td style={{ width: "8%" }} className="action" onClick={() => {
                                    action !== "" && this.handleActionClick(index, action, p[tableConst.rowKey], p[tableConst.partitionKey], p[tableConst.apiName]);
                                }}>{action}
                                </td>
                            </tr>
                        )
                    })}
                </PerfectScrollBar>) : <tbody className="noBodyData"><tr><td>{tableConst.nodatafound}</td></tr></tbody>}
            </table >
        </>
        ))
    }
}

export default APITable;


