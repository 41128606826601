import React from 'react';
import {svgIconsList} from "../../constants/appConstants";
import { CONSTANTS } from "../../constants/stringConstants";

export function HeaderOuter() {
  return (
    <div className='logo-wrapper-outside'>
        <a className='logo' title='Majesco'>
            <img className='logo-img' height={36} width={115} src={svgIconsList.APP_LOGO} alt="majesco logo"></img>
        </a>
        <span className='separator'></span>
        <span className='header-text'>{CONSTANTS.COMMON.appName}</span>
    </div> 
  );
}