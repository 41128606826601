import React, { memo} from "react";
import { Row, Col } from "react-bootstrap";

import { ButtonLoader } from "../../stories/ButtonLoader";

//Component
import APITable from "./table";

const DashboardContent = ({ render, data, dropdownLoader, setDropdownLoader, openSmallPopup, handleOpenModal }) => {

    function calculateCount() {
        const obj = { "Total": data.length, "Queued": 0, "Failed": 0, "In_progress": 0, "Completed": 0, "Skipped": 0, "Fault": 0, };
        data.forEach(row => {
            switch (row.Status) {
                case 'Queued':
                    obj.Queued = obj.Queued + 1
                    break;
                case 'Failed-APIM':
                    obj.Failed = obj.Failed + 1
                    break;
                case 'In progress':
                    obj.In_progress = obj.In_progress + 1
                    break;
                case 'Completed':
                    obj.Completed = obj.Completed + 1
                    break;
                case 'Skipped':
                    obj.Skipped = obj.Skipped + 1
                    break;
                case 'Failed-Metadata':
                    obj.Fault = obj.Fault + 1
                    break;
            }
        });
        return obj;
    }

    const statusCount = calculateCount();
    return (
        <div className="dashboard-content">
            {dropdownLoader && <Row className="table-loader-container" >
                <Col><ButtonLoader className="table-loader" /></Col>
            </Row>}
            <div></div>
            <Row className="statusRow">
                <Col className="total">Total: {statusCount.Total}</Col>
                <Col className="queue">Queued: {statusCount.Queued}</Col>
                <Col className="inprogress">In progress: {statusCount.In_progress}</Col>
                <Col className="complete">Completed: {statusCount.Completed}</Col>
                <Col className="fail">Failed-APIM: {statusCount.Failed}</Col>
                <Col className="fault">Failed-Metadata: {statusCount.Fault}</Col>
                <Col className="skipped">Skipped: {statusCount.Skipped}</Col>                
            </Row>
            <Row>
                <APITable render={render} data={data} setDropdownLoader={setDropdownLoader} openSmallPopup={openSmallPopup} handleOpenModal={handleOpenModal} />
            </Row>
        </div>
    );
};

export default memo(DashboardContent);