import axios from "axios";
import {API_BASE_URL} from "../appConfig";

export const REACT_BASE_API_URL = API_BASE_URL;

const mainInstance = axios.create({
  baseURL: REACT_BASE_API_URL
});

// Alter defaults after instance has been created
export { mainInstance as default };

export const OPERATION_STATUS = {
  SUCCESS: 0,
  FAILURE: 1,
  NOT_FOUND: 2,
  SOMETHING_WENT_WRONG: 3,
};

export const API_ERROR_CODE = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
};
