const API = {
  COMMON_CUSTOMER: "/api/Customers",
  COMMON_PRODUCT: "/api/Products",
  COMMON_ENVIRONMENT: "/api/Environments",
  COMMON_STATUS: "/api/Status",
  COMMON_RELEASE: "/api/Release",
  COMMON_GO: "/api/Release",
  URL_MAP_DATA: "/api/ApiUrlData",
  SEARCH: "/api/Search",
  RETRY: "/api/Retry",
  RESET: "/api/Reset",
  COMMON_GETMASTERKEY:"/api/ValidateTokenAndGetKey"
};

export default API;