import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : '',//To handle refresh state we need to set in secure session storage
  user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : '',
  functionKey: sessionStorage.getItem('x-functions-key') ? sessionStorage.getItem('x-functions-key') : '',
}
const userSlice = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    setUser(state = initialState, action) {
      sessionStorage.setItem('user', JSON.stringify(action.payload));//To handle refresh state we need to set in secure session storage
      return { ...state, user: action.payload };
    },
    setAccessToken(state, action) {
      sessionStorage.setItem('accessToken', action.payload);//To handle refresh state we need to set in secure session storage
      return { ...state, accessToken: action.payload };
    },
    flushToken(state = initialState, action) {//logout
      sessionStorage.removeItem('accessToken');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('x-functions-key');
      return { ...state, accessToken: action.payload };
    },
    setFunctionKey(state = initialState, action) {//logout
      sessionStorage.setItem('x-functions-key',action.payload);
      return { ...state, functionKey: action.payload };
    }
  },
});

export const { setUser, setAccessToken, flushToken, setFunctionKey } = userSlice.actions;
export default userSlice.reducer;
