import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./state/store";
import { Provider } from "react-redux";

//Authentication
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./util/authConfig";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import './responsive.scss';

const msalInstance = new PublicClientApplication(msalConfig);
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);


// Initial render
root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </Provider>
);