import React from 'react';
import PropTypes from 'prop-types';
import './input.css';

/**
 * Primary UI component for user interaction
 */
export const Input = ({ size, label, type, className, autoComplete, ...props }) => {
  return (
    <input
      type={type}
      className={['storybook-input', `storybook-input--${size}`, `${className}`].join(' ')}
      {...props}
      placeholder={label}
      autoComplete={autoComplete}
    />
  );
};

Input.propTypes = {

  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'mediumlarge']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
};

Input.defaultProps = {
  size: 'medium',
  onClick: undefined,
};
