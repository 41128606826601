import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import swal from 'sweetalert';
import { BrowserUtils } from "@azure/msal-browser";

//service
import axios from "axios";
import API from "./services/APIConstants";

//Authentication
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "./util/authConfig";
import { authConfigSetting, API_BASE_URL, USER_GROUP } from "./appConfig";


//reducer actions
import { setAccessToken, setUser, setFunctionKey } from "./state/userSlice";

//dashboard page
import Dashboard from "./components/dashboard/dashboard";
import Error from "./components/error/error";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Login from "./components/login/login";

const App = () => {

  const functionKey = useSelector((state) => state.user.functionKey);
  const isAuthenticated = useIsAuthenticated();//will require later

  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();


  //3) get and check the user group
  const getUserType = (UID, userAccessToken, email) => {
    const header = {
      "Authorization": `${userAccessToken}`
    };

    axios.get(authConfigSetting.graphURL + UID + '/transitiveMemberOf', { headers: header }).then((response) => {
      const userValid = response?.data?.value?.find((item) => item?.displayName?.trim() === USER_GROUP);
      if (userValid) {//user is valid has access to the application
        //adx token
        RequestFunctionKeys(email, userAccessToken, email)
      } else {//force logout
        swal({
          text: "You are not authorized, please consult the app admin for more detail; Redirecting to the login page.",
          button: "Ok",
        }).then(function (inputValue) {
          sessionStorage.removeItem('accessToken');
          sessionStorage.removeItem('user');
          sessionStorage.removeItem('x-functions-key');
          sessionStorage.removeItem('accounts');
          instance.logoutRedirect({
            account: accounts[0],
            onRedirectNavigate: () => !BrowserUtils.isInIframe()
          })
        });
      }
    })
  }

  // 2) update user details
  const updateUserDetails = (data) => {
    let temp = {}, userAccessToken = data.accessToken;
    const email = accounts[0]['username']
    temp['email'] = email;
    temp['name'] = accounts[0]['name'];

    dispatch(setUser(temp));
    dispatch(setAccessToken(userAccessToken));
    // getUserType(data.idTokenClaims.oid, userAccessToken, email);

    RequestFunctionKeys(email, userAccessToken);

  }

  // 1) Silently acquires an access token which is then attached to a request for Microsoft AD data
  function RequestAccessToken() {
    const request = { ...loginRequest, account: accounts[0] };
    sessionStorage.setItem('accounts', JSON.stringify(accounts[0]));
    instance.acquireTokenSilent(request).then((response) => {
      updateUserDetails(response);
    }).catch((e) => { });
  }

  // 4) Silently acquires an access token which is then attached to a request for Microsoft AD data
  const RequestFunctionKeys = async (email, userAccessToken) => {
    const data = {
      "Token": `${userAccessToken}`,
      "EmailId": `${email}`
    };
    axios.post(API_BASE_URL + API.COMMON_GETMASTERKEY, data).then((response) => {
      const functionKey = response.data.masterkey;
      sessionStorage.setItem('x-functions-key', functionKey)
      dispatch(setFunctionKey(functionKey));

    }).catch((error) => {
      const { errorMessage } = error.response.data;
      swal({
        text: "You are not authorized, please consult the app admin for more detail; Redirecting to the login page. Error: " + errorMessage,
        button: "Ok",
      }).then(function (inputValue) {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('x-functions-key');
        sessionStorage.removeItem('accounts');
        instance.logoutRedirect({
          account: accounts[0],
          onRedirectNavigate: () => !BrowserUtils.isInIframe()
        })
      });
    })
  }

  /*if user logged in successfully => 
  1) request tokan silently
  2) Get user details
  3) get and check the user group
  4) if user group valid then get the function key
  */
  useEffect(() => {
    //Contextual search
    var search = window.location.search;
    if (search) {
      window.localStorage.setItem("search", search)
    }
    if (!functionKey || functionKey === '') {
      RequestAccessToken();
    }
  }, [isAuthenticated])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={
          <>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Navigate to={'/dashboard'} replace />
            </AuthenticatedTemplate>
          </>
        } />

        <Route path="/" element={
          <>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Navigate to={'/dashboard'} replace />
            </AuthenticatedTemplate>
          </>
        } />

        <Route path="/dashboard" element={
          <>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <Header />
              <Dashboard />
              <Footer />
            </AuthenticatedTemplate>
          </>
        } />
        {/*Page Not found*/}
        <Route path="/">
          <Route path="*" element={<Error />}></Route>
        </Route>
      </Routes>

    </BrowserRouter>
  );
};

export default App;
