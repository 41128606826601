import React from 'react';
import { CONSTANTS } from "../../constants/stringConstants";

import './footer.scss';

export default function Footer(){
           
    return(
        <>
            <footer className="footer">
                <div className="info-wrapper info">
                    <span className="info__copyright">© </span>
                    <span className="info__date">{CONSTANTS.FOOTER.lblYr}</span>
                    <span className="info__site-title"> {CONSTANTS.FOOTER.lblTitle}&nbsp;</span>
                    <span className="info__privacy-policy">
                        | <a href={CONSTANTS.LINKS.lnkPrivacyPolicy} target="_blank" rel="noopener noreferrer" className="mid-heading privacy-link"> {CONSTANTS.FOOTER.lblPrivacy} </a>
                        | <a href={CONSTANTS.LINKS.lnkCookiesPolicy} target="_blank" rel="noopener noreferrer" className="mid-heading cookie-link"> {CONSTANTS.FOOTER.lblCookie} </a>
                    </span>
                </div>
            </footer>
        </>
    );
}

