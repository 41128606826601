import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from "react-bootstrap";

//Auth
import { useMsal } from "@azure/msal-react";

import { Dropdown } from '../../stories/dropdown';
import { Button } from '../../stories/Button';
import { Heading } from "../../stories/Heading";
import { ButtonLoader } from "../../stories/ButtonLoader";
//utils
import { checkUserTokenValidity } from '../../util/util';

//constant
import { svgIconsList } from "../../constants/appConstants";
import { CONSTANTS, tableConst } from "../../constants/stringConstants";
import PopupContext from "./popupContext";

//service
import { executeQuery } from "../../services/execute";
import API from "../../services/APIConstants";
//state
import { setProducts, setEnvironments, setStatus, setReleases, setActiveCustomer, setCustomers, setInItOnce, setDefaultProducts, setDefaultEnvironments, setDefaultStatus, setDefaultReleases } from "../../state/dashboardSlice"

const DashboardHeader = (props) => {
    const customers = useSelector((state) => state.dashboard.customers)
    const environments = useSelector((state) => state.dashboard.environments)
    const products = useSelector((state) => state.dashboard.products)
    const status = useSelector((state) => state.dashboard.status)
    const createdOn = useSelector((state) => state.dashboard.createdOn)
    const releases = useSelector((state) => state.dashboard.releases)
    const activeCustomers = useSelector((state) => state.dashboard.activeCustomers)
    const inItOnce = useSelector((state) => state.dashboard.inItOnce);

    //defaults
    const defaultStatus = useSelector((state) => state.dashboard.defaultStatus);
    const defaultProducts = useSelector((state) => state.dashboard.defaultProducts);
    const defaultCustomers = useSelector((state) => state.dashboard.defaultCustomers);
    const defaultEnvironments = useSelector((state) => state.dashboard.defaultEnvironments);
    const defaultReleases = useSelector((state) => state.dashboard.setDefaultReleases);

    const dispatch = useDispatch();
    const { instance } = useMsal();

    const [values, setValues] = useState({});
    const [className, setClassName] = useState('disable');
    const [dropdownLoader, setDropdownLoader] = useState(false);

    const popupContext = useContext(PopupContext);

    const openStatusPopup = () => {
        popupContext.openStatusTable();
    }

    const changeDropdown = (value) => {
        let name = value.dropdownName;
        let val = value.Value;

        //Let's set these values in state
        if (name === 'customercodes') {
            val = getCustomerCode(val)
        }

        if (name === 'createdon') {
            val = getStartDate(val)
        }

        setValues({
            ...values,
            [name]: val,
        })
    }

    //return company code by name
    const getCustomerCode = (Value) => {
        let temp = [];
        for (let r in Value) {
            temp[r] = customers.filter((item) => item.Value === Value[r])?.[0]?.['Id'];
        }
        return temp;
    }

    const getStartDate = (date) => {
        return date[0].split(/[()]/)[1];
    }

    const handleGoButton = () => {
        props.handleGoClick(values);
    }

    const handleClearSelection = () => {
        setDropdownLoader(true);
        //reset all dropdown
        dispatch(setEnvironments([]))
        dispatch(setProducts([]))
        dispatch(setStatus([]))
        dispatch(setReleases([]))

        //reset all the values
        setValues({ "createdon": getStartDate(createdOn[0]['Value']) });
        setClassName('disable')//disable all btn

        //clear table
        props.clearTableData();

        //reset customer object
        resetCustomerDropdown();

        //reset act cust
        dispatch(setActiveCustomer(''));

        //hide dropdown loader
        setTimeout(function () {
            setDropdownLoader(false);
        }, 500)

    }

    const resetCustomerDropdown = () => {
        document.querySelector(".dropdown-customercodes span").innerText = "Select a customer";

        //remove active
        function myFunction(e) {
            if (document.querySelector('.dropdown-customercodes .dropdown-menu li.active') !== null) {
                document.querySelector('.dropdown-customercodes .dropdown-menu li.active').classList.remove('active');
            }
        }
        myFunction()
    }

    const downloadCSV = () => {
        const getURLMapData = async () => {
            setDropdownLoader(true);
            const newValues = { ...values }
            const customerNames = getCustomerName(values.customercodes);
            newValues.customercodes = customerNames;

            const list = await executeQuery(API.URL_MAP_DATA, "POST", newValues);
            if (Array.isArray(list.data) && list.data.length > 0) {
                let headers = "Sr.No,Client Name,Environment,Product,API Name,Frontend URL,Backend URL,Release,Status";
                //create row array of string
                const dataArray = list.data.map((obj, index) => {
                    let {
                        [tableConst.customer]: Customer, [tableConst.environment]: Environment, [tableConst.product]: Product, [tableConst.apiName]: API_Name, [tableConst.FrontendURL]: FrontendURL, [tableConst.BackendURL]: BackendURL, [tableConst.release]: Release, [tableConst.status]: Status
                    } = obj;
                    return (index + 1) + "," + Customer + "," + Environment + "," + Product + "," + API_Name + "," + FrontendURL + "," + BackendURL + "," + Release + "," + Status
                    //  const str=Object.values(obj).join(","); 
                }
                )
                const csv_dataonly = headers + "\n" + dataArray.join("\n");
                const CSVFile = new Blob([csv_dataonly], {
                    type: "text/csv"
                });
                var temp_link = document.createElement('a');
                temp_link.download = "URLMapping-" + new Date().toLocaleDateString() + ".csv";
                var url = window.URL.createObjectURL(CSVFile);
                temp_link.href = url;
                temp_link.style.display = "none";
                document.body.appendChild(temp_link);
                temp_link.click();
                document.body.removeChild(temp_link);
            } else {
                props.openSmallPopup("Download", tableConst.nodatafound);
            }
            setDropdownLoader(false);
        }
        getURLMapData();
        //check user token validaty
        checkUserTokenValidity(instance);
    }

    const getCustomerName = (Value) => {
        let temp = [];
        for (let r in Value) {
            temp[r] = customers.filter((item) => item.Id === Value[r])?.[0]?.['Value'];
        }
        return temp;
    }

    const fetchData = async (objToSend) => {
        let temp = [];
        var _products, _enviroments, _status, _releases;

        //Contextual search
        if (window.location.search && inItOnce) {
            objToSend["customercodes"] = [customers.find((cust => cust.Value === defaultCustomers))?.Id];
        }

        const getProducts = async () => {
            const list = await executeQuery(API.COMMON_PRODUCT, "POST", objToSend);
            _products = list.data;
            temp['products'] = [list?.data?.[0]?.['Value']];
        }

        const getEnvironments = async () => {
            const list = await executeQuery(API.COMMON_ENVIRONMENT, "POST", objToSend);
            temp['environments'] = [list?.data?.[0]?.['Value']];
            _enviroments = list.data
        }

        const getStatus = async () => {
            const list = await executeQuery(API.COMMON_STATUS, "POST", objToSend);
            temp['status'] = defaultStatus.split(',');//default status sel (Queued,In progress,Failed)
            _status = list.data
        }

        const getReleases = async () => {
            const list = await executeQuery(API.COMMON_RELEASE, "POST", objToSend);
            temp['releases'] = [list?.data?.[0]?.['Value']];
            _releases = list.data;
        }
        //check user token validity
        checkUserTokenValidity(instance);

        //Promise.all added to make parallel api call and wait on all api call before search.
        Promise.all([getProducts(), getEnvironments(), getStatus(), getReleases()]).then(() => {
            const selectedValues = values.length ? values : objToSend;
            dispatch(setProducts(_products));
            dispatch(setEnvironments(_enviroments));
            dispatch(setStatus(_status))
            dispatch(setReleases(_releases))
            var allProductValue = _products.map((el) => el.Value);
            //default selection call for go button API called in the page load only
            if (inItOnce) {
                //Contextual search
                if (window.location.search) {//if there is search criteria in url and its first render
                    var iterator = new URLSearchParams(window.location.search).entries();
                    for (let item of iterator) {
                        var name = item[0];
                        switch (name) {
                            case "c":
                                //dispatch(setDefaultCustomers(searchData[1]))
                                break;
                            case "e":
                                dispatch(setDefaultEnvironments(item[1]))
                                temp['environments'] = [item[1]];
                                break;
                            case "prod":
                                dispatch(setDefaultProducts(item[1]))
                                temp['products'] = [item[1]];
                            case "s":
                                let st = item[1].split(";").join();
                                temp['status'] = item[1]?.split(";");
                                dispatch(setDefaultStatus(st))
                                break;
                            case "r":
                                dispatch(setDefaultReleases(item[1]))
                                temp['releases'] = [item[1]];
                                break;
                        }
                    }
                } else {
                    dispatch(setDefaultProducts(allProductValue.join()));
                    dispatch(setDefaultEnvironments(_enviroments?.[0]?.Value));
                    dispatch(setDefaultReleases(_releases?.[0]?.Value));
                    temp['products'] = allProductValue;
                }
                setValues({
                    ...selectedValues,
                    ...temp
                });
                props.handleGoClick({ ...selectedValues, ...temp });
            }
            else {
                dispatch(setDefaultProducts(_products?.[0]?.Value));
                dispatch(setDefaultEnvironments(_enviroments?.[0]?.Value));
                dispatch(setDefaultReleases(_releases?.[0]?.Value));

                setValues({
                    ...selectedValues,
                    ...temp
                })
            }
            dispatch(setInItOnce(false))//disable call once flag
            setDropdownLoader(false);
            setClassName('');
        })
    }

    const handleinItOnceFirstTenant = (initialValue) => {
        if (inItOnce) {
            //restart dropdown APIs
            setDropdownLoader(true);
            fetchData(initialValue);
        }
    }

    //this will be called once we get the customer data
    useEffect(() => {
        function inItDefaultData() {
            const defaultCustomer = customers?.[0]?.['Id'];

            if (defaultCustomer) {
                const initialValues = {
                    ['createdon']: getStartDate([createdOn[0]['Value']]),
                    ['customercodes']: [defaultCustomer]
                }
                setValues({
                    ...values,
                    initialValues
                })

                //called once
                handleinItOnceFirstTenant(initialValues)
            }
        }

        inItDefaultData();
    }, [customers])


    //this will be called whenever we change the customer dropdown and init other APIs
    useEffect(() => {
        function inItDropDownAPI() {
            if (!activeCustomers && customers.length) {
                handleClearSelection()//reset all dropdowm for no customer
            } else {
                if (values['customercodes']) {
                    //remove "select a custome" item and reset (after clear selection)
                    const temp = customers.filter((item) => item.Id !== 0)
                    dispatch(setCustomers(temp));

                    //restart dropdown APIs
                    setDropdownLoader(true);
                    fetchData({ "customercodes": values['customercodes'], 'createdon': getStartDate([createdOn[0]['Value']]) });
                }
            }
        }

        inItDropDownAPI();
    }, [activeCustomers])

    return (
        <>
            <Row className="dashboard-header">
                <Heading label="" size="mid-medium" className="dashboard-heading">{CONSTANTS.DASHBOARD.title}</Heading>
                {!props.isDataAvailable && <Heading label="" size="small" className="dashboard-heading">{CONSTANTS.DASHBOARD.lalNoData}</Heading>}
                {dropdownLoader && <Row className="dropdown-loader-container" >
                    <Col><ButtonLoader className="dropdown-loader" /></Col>
                </Row>}
                <Row>
                    <Col>
                        <Heading label="" size="small">{CONSTANTS.DASHBOARD.lblCutomer}</Heading>
                        {customers.length ? <Dropdown name="customercodes" default={defaultCustomers} dropdownList={customers} changeDropdown={changeDropdown} dropdownLoader={dropdownLoader} /> : <div className="dropdown dropdown-customercodes"><button type="button" aria-label="button" className="storybook-button storybook-button--large inprogress-true storybook-button--secondary storybook-button--border"><span aria-label="Select a customer" className="" data-mui-internal-clone-element="true">{CONSTANTS.DASHBOARD.lblselectCust}</span><img height="15" width="10" className="icons" src={svgIconsList.DARROW} alt="select customer" /></button></div>}

                    </Col>
                    <Col>
                        <Heading label="" size="small">{CONSTANTS.DASHBOARD.lblEnvironment}</Heading>
                        <Dropdown name="environments" default={defaultEnvironments} dropdownList={environments} changeDropdown={changeDropdown} />
                    </Col>
                    <Col>
                        <Heading label="" size="small">{CONSTANTS.DASHBOARD.lblProduct}</Heading>
                        <Dropdown name="products" default={defaultProducts} dropdownList={products} changeDropdown={changeDropdown} option={'multiselect'} />
                    </Col>
                    <Col>
                        <Heading label="" size="small">{CONSTANTS.DASHBOARD.lblStatus + " "}<span className={"whatisit"} onClick={openStatusPopup}>(What is it?)</span></Heading>
                        {/* default status sel (Queued,In progress,Failed) */}
                        <Dropdown name="status" default={defaultStatus} dropdownList={status} changeDropdown={changeDropdown} option={'multiselect'} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Heading label="" size="small">{CONSTANTS.DASHBOARD.lblRelease}</Heading>
                        <Dropdown name="releases" default={defaultReleases} dropdownList={releases} changeDropdown={changeDropdown} option={'multiselect'} />
                    </Col>
                    <Col lg={4}>
                        <Heading label="" size="small">{CONSTANTS.DASHBOARD.lblCreatedon}</Heading>
                        <Dropdown name="createdon" default={createdOn?.[0]?.Value} dropdownList={createdOn} changeDropdown={changeDropdown} />
                    </Col>
                    <Col className="go-btn" lg={5}>
                        <Button className={className} label="" type="button" primary onClick={() => handleGoButton()} size='small'>
                            {CONSTANTS.DASHBOARD.lblGoBtn}
                        </Button>
                        <Button label="" type="button" primary onClick={() => handleClearSelection()} size='small'>{CONSTANTS.DASHBOARD.lblResetBtn}</Button>
                        <Button className={className} label="" type="button" onClick={() => { downloadCSV() }} primary size='small'>{CONSTANTS.DASHBOARD.lblMapBtn}</Button>
                    </Col>
                </Row>
            </Row>
            <br />
        </>
    );
};

export default DashboardHeader;
