import React from 'react';
import PropTypes from 'prop-types';
import './heading.scss';

/**
 * Primary UI component for user interaction
 */
export const Heading = ({ size, label,className,prepend,color, ...props }) => {
 
 if(prepend){
  return (
      <h1
        className={['storybook-heading semibold', `storybook-heading--${size} ${className}`].join(' ')}
        style={color && { color }}
        {...props}
      >
        {props.children}
        {label}
      </h1>
    );
 }else{
   return (
      <h1
        className={['storybook-heading semibold', `storybook-heading--${size} ${className}`].join(' ')}
        style={color && { color }}
        {...props}
      >
        {label}
        {props.children}
      </h1>
    );
 }
  
};

Heading.propTypes = {
  /**
   * How large should the beading be?
   */
  size: PropTypes.oneOf(['extra-small','small','mid-medium', 'medium', 'large']),
  /**
   * Heading contents
   */
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  prepend:PropTypes.bool,
 
};

Heading.defaultProps = {
  size: 'large',
  color: null,
  prepend:false,
  label:''
};
