import jwt from 'jwt-decode';
import swal from 'sweetalert';
import { BrowserUtils } from "@azure/msal-browser";

export const isValidEmailID = (emailString) => {
  let emailPattern =
    /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i;
  return emailString.match(emailPattern);
};

export function checkUserTokenValidity(instance) {
  const idToken = sessionStorage.getItem('accessToken')?sessionStorage.getItem('accessToken'):'';
  const account = sessionStorage.getItem('accounts')?JSON.parse(sessionStorage.getItem('accounts')):'';
  if(idToken){
      if (jwt(idToken).exp < (Date.now() / 1000)){
          swal({
              text: "Your session is expired, please re-login.",
              button: "Ok",
          }).then(function(inputValue){
                sessionStorage.removeItem('accessToken');
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('x-functions-key');
                sessionStorage.removeItem('accounts');
                instance.logoutRedirect({
                    account: account,
                    onRedirectNavigate: () => !BrowserUtils.isInIframe()
                })
          });
      }
  }
}