import { callAPI } from "./axiosTemplate";
import {API_BASE_URL} from "../appConfig";
import API from "./APIConstants";
import { OPERATION_STATUS } from "./axios.init";

export const executeQuery = async (action, httpMethod, parameters = []) => {
    const url = process.env.REACT_APP_API_URL || API_BASE_URL;
    //build query with parameters
    const query =  getQueryFromAction(action);

    let requestBody = parameters;

    //execute Query
    let APIResponse = await callAPI(url + query, httpMethod, requestBody);
    if (APIResponse && APIResponse.status !== OPERATION_STATUS.SUCCESS) {
        // Error scenario
        return APIResponse.error;
    }
    return await populateResponseForUI(action, APIResponse);
}


const populateResponseForUI = async (action, apiResponse) => {
    let formatedAPIResponse = "";
    switch (action) {
        case API.RETRY:
            formatedAPIResponse = { data: apiResponse.data["Data"], message: apiResponse.data["Message"], status: apiResponse.data["Status"] };
            break;
        case API.RESET:
            formatedAPIResponse = { data: apiResponse.data["Data"], message: apiResponse.data["Message"], status: apiResponse.data["Status"] };
            break;
        default:
            formatedAPIResponse = apiResponse;
            break;
    }
    return formatedAPIResponse;
}

const getQueryFromAction = (action) => {
    switch (action) {
        case API.COMMON_CUSTOMER:
            return API.COMMON_CUSTOMER;
        case API.COMMON_ENVIRONMENT:
            return API.COMMON_ENVIRONMENT;
        case API.COMMON_PRODUCT:
            return API.COMMON_PRODUCT;
        case API.COMMON_STATUS:
           return API.COMMON_STATUS;
        case API.COMMON_RELEASE:
             return API.COMMON_RELEASE;
        case API.URL_MAP_DATA:
            return API.URL_MAP_DATA;
        case API.SEARCH:
            return API.SEARCH;
        case API.RETRY:
            return API.RETRY;
        case API.RESET:
            return API.RESET;
        default:
            return "";
    }
};