import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment';
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        defaultStatus: "Failed-APIM,Failed-Metadata",
        defaultProducts: '',
        defaultCustomers: '',
        defaultEnvironments: '',
        defaultReleases: '',

        activeCustomers: '',
        customers: [],
        products: [],
        environments: [],
        status: [''],
        releases: [],
        createdOn: [{ 'Id': 1, "Value": 'Current month ' + getCurrentMonth('Current month') }, { 'Id': 2, "Value": 'Last month ' + getCurrentMonth('Last month') }, { 'Id': 3, "Value": 'Last 3 months ' + getCurrentMonth('Last 3 months') }],
        apiTableData: [],
        inItOnce: true,
    },
    reducers: {

        setCustomers(state, action) {
            return { ...state, customers: action.payload };
        },
        setProducts(state, action) {
            return { ...state, products: action.payload };
        },
        setEnvironments(state, action) {
            return { ...state, environments: action.payload };
        },
        setStatus(state, action) {
            return { ...state, status: action.payload };
        },
        setReleases(state, action) {
            return { ...state, releases: action.payload };
        },
        setActiveCustomer(state, action) {
            return { ...state, activeCustomers: action.payload };
        },
        setInItOnce(state, action) {
            return { ...state, inItOnce: action.payload };
        },
        setDefaultProducts(state, action) {
            return { ...state, defaultProducts: action.payload };
        },
        setDefaultCustomers(state, action) {
            return { ...state, defaultCustomers: action.payload };
        },
        setDefaultEnvironments(state, action) {
            return { ...state, defaultEnvironments: action.payload };
        },
        setDefaultStatus(state, action) {
            return { ...state, defaultStatus: action.payload };
        },
        setDefaultReleases(state, action) {
            return { ...state, defaultReleases: action.payload };
        }
    },
});

function getCurrentMonth(month) {
    let date = new Date();
    let dates = [];
    if (month === 'Current month') {
        dates['firstDay'] = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD');
        dates['lastDay'] = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD');
    } else if (month === 'Last month') {
        dates['firstDay'] = moment(new Date(date.getFullYear(), date.getMonth() - 1, 1)).format('YYYY-MM-DD');
        dates['lastDay'] = moment(new Date(date.getFullYear(), date.getMonth(), 0)).format('YYYY-MM-DD');
    } else {
        dates['firstDay'] = moment(new Date(date.getFullYear(), date.getMonth() - 3, 1)).format('YYYY-MM-DD');
        dates['lastDay'] = moment(new Date(date.getFullYear(), date.getMonth(), 0)).format('YYYY-MM-DD');
    }

    return "(" + dates['firstDay'] + '/' + dates['lastDay'] + ")";
}

export const { setDefaultCustomers, setDefaultEnvironments, setDefaultProducts, setDefaultStatus, setDefaultReleases, setCustomers, setProducts, setEnvironments, setStatus, setReleases, setActiveCustomer, setInItOnce } = dashboardSlice.actions;
export default dashboardSlice.reducer;
