export const CONSTANTS = {
  COMMON: {
    appName: "APIM",
  },
  LINKS: {
    lnkPrivacyPolicy: "https://www.majesco.com/privacy-policy/",
    lnkCookiesPolicy: "https://www.majesco.com/cookie-policy/",
    lnkServiceNow: "https://majescoprod.service-now.com//",
  },
  HEADER: {
    lblLogoutBtn: 'Logout'
  },
  FOOTER: {
    lblYr: '2023',
    lblTitle: 'Majesco',
    lblPrivacy: 'Privacy Policy',
    lblCookie: 'Cookie Policy'
  },
  DASHBOARD: {
    lblHeader: "Dashboard",
    lblGoBtn: "Go",
    lblResetBtn: "Clear selection",
    lblMapBtn: "Download URL file",
    title:"API Publishing Status Tracker",
    lblCutomer:"Customers",
    lblEnvironment:"Environments",
    lblProduct:"Products",
    lblStatus:"Status",
    lblRelease:"Release",
    lblCreatedon:"Created on",
    lblselectCust:"Select a customer",
    lalNoData:'No customer data is available.'
  },
  ERROR: {
    code: "404",
    lblHeader: "PAGE NOT FOUND",
    infoMessage1: "Oops. The future of insurance doesn't include pages that don't exist.",
    infoMessage2: "Back to Majesco Home Page"
  },
  LOGIN: {
    loginInstruction: "Sign in to APIM",
    lblEmailField: "Enter in your e-mail ID",
    lblLoginButton: "Continue to sign in",
    helpLink: "Need help signing in?",
    errorMessage: "Please enter e-mail ID.",
  }
};

export const tableConst = {
  apiName: "API Name",
  status: "Status",
  createdOn: "Created On",
  updatedOn: "Modified On",
  message: "Message",
  messageDescription: "Description",
  partitionKey: "PartitionKey",
  rowKey: "RowKey",
  //csv
  environment: "Environment",
  product: "Product",
  release: "Release",
  customer: "Customer",
  FrontendURL:"Frontend URL",
  BackendURL:"Backend URL",
  //end
  inProgress: "In progress",
  failed: "Failed-APIM",
  queued: "Queued",
  retry: "Retry",
  reset: "Reset",
  nodatafound: 'No record found! Please change the criteria and try again.',
  success: "Success",
  failure: "Failure",
  retryfailed: "Retry failed! Please check the error and try again.",
  resetFailed: "Reset failed! Please check the error and try again.",
  norecordfound: "No record found",
}
