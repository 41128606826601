import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { Heading } from '../../stories/Heading';


import { svgIconsList } from "../../constants/appConstants";
import { CONSTANTS } from "../../constants/stringConstants";

//Auth
import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { flushToken } from "../../state/userSlice";

import './header.scss'

export default function Header() {
    const user = useSelector((state) => state.user.user);

    const [isHovering, setIsHovering] = useState(false);

    const { instance, accounts } = useMsal();
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);


    const handleLogout = () => {
        //remove all the sessions
        dispatch(flushToken(""));
        //Contextual search
        window.localStorage.removeItem("search")
        instance.logoutRedirect({ account: accounts[0], onRedirectNavigate: () => !BrowserUtils.isInIframe() })
    }

    //close dropdown when click outside
    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) { setIsHovering(false); }
    };
    // detect outside click event handle here
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
    }, []);

    return (

        <header className="site-header">
            <Container fluid >
                <Row>
                    <Col md={8} sm={8} lg={8} className='vertical-center'>
                        <div className={`logo-wrapper `} aria-labelledby="main-header">
                            <a className='logo' title='Majesco'>
                                <img height={36} width={115} src={svgIconsList.APP_LOGO} alt="majesco logo"></img>
                            </a>
                            <span className='separator'></span>
                            <span className='header-text'>{CONSTANTS.COMMON.appName}</span>
                        </div>
                    </Col>
                    <Col md={4} sm={4} lg={4} ref={wrapperRef}>
                        <Row className="right-user-ul">
                            <Col lg={3}>
                                <div className="user-dash-pros image-upload" aria-labelledby="img"
                                    style={{ backgroundImage: `url("assets/profile.jpg")` }} />
                            </Col>
                            <Col lg={9}>
                                <Heading label='' size='extra-small' onClick={() => setIsHovering(!isHovering)}>
                                    {user.name}
                                    <img className="icons" src={svgIconsList.DARROW} alt='menu open' width='12' height='12' />
                                </Heading>
                            </Col>
                        </Row>

                        <div className="right-user-ul" aria-labelledby="dropdown">
                            {isHovering && <div className="pro-drop-downhead">
                                <ul className="clearfix">
                                    <li>
                                        <a className="mid-heading logout" onClick={() => handleLogout()}>
                                            {CONSTANTS.HEADER.lblLogoutBtn}
                                        </a>
                                    </li>
                                </ul>
                            </div>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}



