import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

/**
 * Primary UI component for user interaction
 */
export const Button = ({ border, primary, backgroundColor, size, label,className, ...props }) => {
  const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';
  const buttonBorder = border ? 'storybook-button--border' : '';
  
  
  return (
    <button
      type="button"
      aria-label='button'
      className={['storybook-button', `storybook-button--${size} ${className}`, mode, buttonBorder].join(' ')}
      style={backgroundColor && { backgroundColor }}
      {...props}
    >
      {props.children}
      {label}
    </button>
  );
};

Button.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  primary: PropTypes.bool,
  /**
   * What background color to use
   */
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large','extra-large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func
};

Button.defaultProps = {
  backgroundColor: null,
  primary: false,
  size: 'medium',
  onClick: undefined,
  label:"",
  border:false
};
